<template>
  <div
    class="w-1/3 m-auto min-h-screen"
    style="background: url(error.png) no-repeat top center; background-size: contain;"
  ></div>
</template>

<script>
export default {
  name: 'error',
  props: ['value'],
  components: {},
  data() {
    return {}
  },
}
</script>

<style></style>
